.SplashScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-circle {
  width: 50px;
  height: 50px;
  border: 5px solid #3498db;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.name-container {
  font-size: 24px;
  margin-top: 20px;
  opacity: 0;
  animation: pop-up 1s forwards;
  cursor: pointer; /* Add a pointer cursor on hover */
  transition: transform 0.2s, color 0.2s; /* Add transition effects */
  font-family: "Lobster", cursive; /* Apply the Lobster font */
}

.name-container:hover {
  transform: scale(1.1);
  color: #e74c3c;
  font-family: "Inter", sans-serif; /* Apply the Inter font */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pop-up {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
